<template>
  <Employees :is-user-page="true"/>
</template>

<script>
import Employees from '@/views/users/Employees.vue'

export default {
  name: 'UserList',
  components: {
    Employees,
  },
}
</script>

<style scoped>

</style>
